// główny layout - górna belka nagłówka

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import qs from 'query-string';

import { useGetLayoutLanguages, useGetLayoutUrlResolverToLanguage } from 'api';
import { DropDown } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const HeaderTopBar = () => {
  const { i18n, t } = useTranslation();
  const { pathname, search } = useLocation();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2)).replace('/', '');

  // pobranie listy dostępnych języków
  const { data: languagesData } = useGetLayoutLanguages();

  // pobranie url_key po zmianie języka
  const { mutateAsync: refetchUrlResolverToLanguage } = useGetLayoutUrlResolverToLanguage();

  const title = languagesData?.items.find((language) => language.id === urlPrefix);

  const handleChangeLanguage = async (id: string) => {
    const urlKey = pathname.replace(urlPrefix, '').replaceAll('/', '');

    try {
      const data = await refetchUrlResolverToLanguage({
        urlKey,
        fromLanguage: urlPrefix,
        toLanguage: id
      });

      i18n.changeLanguage(id).then(() => {
        const params = {
          ...qs.parse(search),
          ...qs.parse(data.parameters)
        };

        localStorage.setItem('LANGUAGE', id);

        window.location.href =
          window.location.origin + `/${id}/${data.url_shortcut}?${qs.stringify(params)}`;
      });
    } catch (err) {
      localStorage.setItem('LANGUAGE', id);

      window.location.href = window.location.href.replace(`/${urlPrefix}`, `/${id}`);
    }
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        styles.languagesWrapper,
        'StylePath-Components-Containers-Languages'
      )}>
      <Helmet>
        <title>{title?.title_website}</title>
        <meta name="description" content={title?.description_website} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <span>{t('Wybierz język')}</span>
      <DropDown
        label={
          <div className={styles.language}>
            <div>
              <span>{i18n.language.toUpperCase()}</span>
            </div>
            <ChevronDown className={styles.arrowDown} />
          </div>
        }
        items={
          languagesData?.items.map((lang) => ({
            label: <span className={styles.language}>{lang.name}</span>,
            onClick: () => {
              handleChangeLanguage(lang.id);
            }
          })) || []
        }
        withDropdownIcon={false}
      />
    </div>
  );
};

export default HeaderTopBar;
