// strona finalizacji koszyka

import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import qs from 'query-string';

import {
  useGetCart,
  useGetCartMainData,
  useGetCartsAll,
  usePostCartPreConfirm,
  usePostCartConfirmVmp,
  usePutCartMainData,
  useGetCartDeliveryMethods,
  usePostCartValidate,
  useGetCartPositions,
  usePutCartNewsletter
} from 'api';
import { reduxActions, useDispatch } from 'store';
import { ICommandResponseError, ICheckoutStep, ICartMainData } from 'api/types';
import { useAppNavigate, useNotifications, useRWD } from 'hooks';
import { CartSummary } from 'components/containers';
import { Container, Breadcrumbs } from 'components/controls';
import { Payer, Payment, Delivery, Confirmation, Positions } from './components';

import styles from 'theme/pages/Checkout/Checkout.module.scss';

const Checkout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const { showErrorMessage } = useNotifications();
  const { isMobile } = useRWD();

  const location = useLocation();

  // ustawienie aktualnego kroku
  const checkoutStep = qs.parse(location.search)?.checkoutStep as ICheckoutStep;
  const setCheckoutStep = (step: ICheckoutStep) =>
    navigate(`/checkout/${cartId}?checkoutStep=${step}`);

  // ID koszyka z url'a
  const { cartId: cartIdParam } = useParams();
  // rzutowanie ID na numer
  const cartId = useMemo(() => parseInt(cartIdParam || ''), [cartIdParam]);

  // dane receivera
  const [receiver, setReceiver] = useState<ICartMainData['receiver']>();

  // czy jest widok podsumowania
  const [comment, setComment] = useState('');

  // włączenie loadera podczas składania zamówienia
  const [isLoading, setIsLoading] = useState(false);

  // flaga newslettera
  const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(false);

  // czy jest widok podsumowania
  const [validationErrors, setValidationErrors] = useState<ICommandResponseError['error_fields']>();

  // czy jest widok potwierdzenia
  const [isConfirmation, setIsConfirmation] = useState(false);

  // parametry zapytania API dla pozycja koszyka
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: '',
    sort_method: ''
  });

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    if (checkoutStep === 'BILLING') {
      dispatch(
        reduxActions.setBreadcrumbs([
          {
            name: t('Twój koszyk'),
            path: `/cart/${cartId}`
          },
          {
            name: t('Dostawa i płatność'),
            path: `/checkout/${cartId}?checkoutStep=BILLING`
          }
        ])
      );
    }

    if (checkoutStep === 'SUMMARY') {
      dispatch(
        reduxActions.setBreadcrumbs([
          {
            name: t('Twój koszyk'),
            path: `/cart/${cartId}`
          },
          {
            name: t('Dostawa i płatność'),
            path: `/checkout/${cartId}?checkoutStep=BILLING`
          },
          {
            name: t('Podsumowanie zamówienia'),
            path: `/checkout/${cartId}?checkoutStep=SUMMARY`
          }
        ])
      );
    }
  }, [checkoutStep, cartId]);

  // ustawienie w url stanu początkowego
  useEffect(() => {
    if (!checkoutStep) {
      setCheckoutStep('ADDRESS');
    }
  }, []);

  // ustawienie komentarza z responsu (i wyczyszczenie błędów) z api (przy poejściu pomiędzy widokiem podsumowania i checkoutu)
  useEffect(() => {
    if (checkoutStep === 'SUMMARY') {
      setComment(cartMainData?.comment || '');
      setValidationErrors(undefined);
    }
  }, [checkoutStep]);

  // scroll do góry przy zmianie kroku procesu zakupowego
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [checkoutStep]);

  // reset stanu po zmienie ID koszyka
  useEffect(() => {
    setIsConfirmation(false);
  }, [cartId]);

  // pobranie wszystkich koszyków
  const { refetch: refetchAllCarts } = useGetCartsAll({
    onSuccess: (data) => {
      const currentCartItem = data.items.find((item) => item.id === cartId);

      if (!isConfirmation) {
        currentCartItem
          ? currentCartItem.products_count > 0
            ? refetchCartData()
            : navigate(`/cart/${cartId}`)
          : navigate('/');
      }
    }
  });

  // pobranie szczegółów koszyka
  const { data: cartData, refetch: refetchCartData } = useGetCart(cartId, {
    enabled: false,
    onError: () => {
      navigate('/');
    }
  });

  // pobranie listy metod dostawy
  const { data: cartDeliveryMethodsData, refetch: refetchDeliveryMethodsData } =
    useGetCartDeliveryMethods(
      cartId,
      {
        page: 1,
        limit: 999
      },
      { enabled: false }
    );

  // pobranie listy pozycji koszyka
  const { data: cartPositionsData, refetch: refetchCartPositions } = useGetCartPositions(
    cartId,
    queryParams,
    {
      enabled: false
    }
  );

  // walidacja poprawności koszykia
  const { mutate: preConfirmCart } = usePostCartPreConfirm(cartId, {
    onSuccess: () => {
      setValidationErrors(undefined);
    },
    onError: (error) => {
      setValidationErrors(error.error_fields);
    }
  });

  // zapis do newslettera
  const { mutateAsync: changeCartNewsletter } = usePutCartNewsletter(cartId);

  // pobranie main data koszyka
  const {
    data: cartMainData,
    refetch: refetchCartMainData,
    isFetching: isMainDataLoading
  } = useGetCartMainData(cartId, {
    onSuccess: (data) => {
      !cartMainData && setComment(data.comment || '');

      // odznaczenie błędu walidacji
      setValidationErrors((prevState) =>
        prevState?.filter(
          (item) => !data[item.property_path as 'receiver' | 'delivery_method' | 'payment_method']
        )
      );
    }
  });

  // aktualizacja main data koszyka
  const {
    mutate: putCartMainData,
    mutateAsync: putCartMainDataAsync,
    isLoading: isMainDataUpdating
  } = usePutCartMainData(cartId, {
    onSuccess: (data, variables) => {
      refetchAllCarts();
      refetchCartMainData();
      if (typeof variables.comment !== 'undefined') {
        setComment(variables.comment);
        setValidationErrors((prevState) =>
          prevState?.filter((item) => item.property_path !== 'comment')
        );
      }

      if (!data.data && data.message) {
        showErrorMessage(data.message);
      }
    },
    onError: (error) => {
      setValidationErrors(error.error_fields);
    }
  });

  // walidacja koszyka
  const { mutateAsync: validateCart } = usePostCartValidate(cartId || 0, {
    onSuccess: (data) => {
      setIsLoading(false);
      if (!data.status) {
        refetchCartData();
        refetchCartMainData();
        refetchCartPositions();
      }
    },
    onError: () => {
      setIsLoading(false);
      refetchCartData();
      refetchCartMainData();
      refetchCartPositions();
    }
  });

  // potwiedznie koszyka (złożenie zamówienia)
  const { mutate: confirmCart } = usePostCartConfirmVmp(cartId, {
    onSuccess: () => {
      setIsLoading(false);
      setIsConfirmation(true);
      refetchAllCarts();
    },
    onError: () => {
      setIsLoading(false);
    }
  });

  const getCurrentStepData = () => {
    switch (checkoutStep) {
      case 'BILLING':
        return {
          currentStepIndex: 1,
          title: '',
          content: (
            <div className={styles.columnWrapper}>
              <div className={styles.column}>
                <div className={styles.tile}>
                  <Payer
                    cartId={cartId}
                    customer={cartMainData?.customer}
                    receiver={cartMainData?.receiver}
                    dropShipping={cartMainData?.dropshipping}
                    refetchCartMainData={refetchCartMainData}
                    updateCartMainData={putCartMainData}
                    setCheckoutStep={setCheckoutStep}
                    setReceiver={setReceiver}
                  />
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.tile}>
                  <div className={styles.paymentDeliveryWrapper}>
                    <Payment
                      cartId={cartId}
                      updateCartMainData={putCartMainDataAsync}
                      paymentMethod={cartMainData?.payment_method}
                      cashOnDelivery={cartMainData?.cash_on_delivery_value}
                      currency={cartData?.currency}
                      refetchDeliveryMethodsData={refetchDeliveryMethodsData}
                      isLoading={isMainDataLoading || isMainDataUpdating}
                    />
                    <Delivery
                      cartId={cartId}
                      updateCartMainData={putCartMainData}
                      deliveryMethod={cartMainData?.delivery_method}
                      deliveryPoint={cartMainData?.receiver_delivery_point}
                      setCheckoutStep={setCheckoutStep}
                      refetchCartMainData={refetchCartMainData}
                      cartDeliveryMethodsData={cartDeliveryMethodsData}
                      refetchDeliveryMethodsData={refetchDeliveryMethodsData}
                      isLoading={isMainDataLoading || isMainDataUpdating}
                    />
                  </div>
                  <div className={styles.comment}>
                    <div
                      className={classnames({
                        [styles.header]: true,
                        [styles.error]: !!commentError
                      })}>
                      <span>
                        <Trans>
                          Jeśli chcesz dodać komentarz do zamówienia prosimy napisać go w polu
                          poniżej.
                        </Trans>
                      </span>{' '}
                    </div>
                    <div
                      className={classnames({
                        [styles.content]: true
                      })}>
                      <TextareaAutosize
                        className={styles.textarea}
                        value={comment}
                        minRows={7}
                        onChange={(event) => setComment(event.target.value)}
                        onBlur={() =>
                          comment !== cartMainData?.comment
                            ? putCartMainData({
                                comment
                              })
                            : setValidationErrors((prevState) =>
                                prevState?.filter((item) => item.property_path !== 'comment')
                              )
                        }
                      />
                      {commentError && <span className={styles.errorMessage}>{commentError}</span>}
                    </div>
                  </div>
                  <CartSummary
                    cartId={cartId}
                    buttonOnClick={handleOrderButtonClick}
                    buttonLabel={t('Zamawiam')}
                    isLoading={isLoading}
                    isMobileCheckoutBottom={isMobile}
                    paymentMethod={cartMainData?.payment_method}
                    deliveryMethod={cartMainData?.delivery_method}
                    isSubscribed={cartMainData?.is_client_subscribed_to_newsletter}
                    isNewsletterSubscribed={isNewsletterSubscribed}
                    setIsNewsletterSubscribed={setIsNewsletterSubscribed}
                    isBilling
                  />
                </div>
              </div>
            </div>
          )
        };
      case 'SUMMARY':
        return {
          currentStepIndex: 2,
          title: '',
          content: (
            <>
              <Payer
                cartId={cartId}
                customer={cartMainData?.customer}
                receiver={cartMainData?.receiver}
                updateCartMainData={putCartMainData}
                setCheckoutStep={setCheckoutStep}
                isSummary={checkoutStep === 'SUMMARY'}
                cashOnDelivery={cartMainData?.cash_on_delivery_value}
                deliveryPoint={cartMainData?.receiver_delivery_point_summary}
                currency={cartData?.currency}
                paymentMethod={cartMainData?.payment_method}
                deliveryMethod={cartMainData?.delivery_method}
                comment={cartMainData?.comment}
              />
              <div className={styles.summary}>
                <Positions
                  cartId={cartId}
                  cartPositionsData={cartPositionsData}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  refetchCartPositions={refetchCartPositions}
                />

                <CartSummary
                  cartId={cartId}
                  buttonOnClick={handleOrderButtonClick}
                  buttonLabel={
                    checkoutStep === 'SUMMARY'
                      ? t('Płacę i zamawiam')
                      : t('Przejdź do podsumowania')
                  }
                  isLoading={isLoading}
                  isSummary={checkoutStep === 'SUMMARY'}
                  isMobileCheckoutBottom={isMobile}
                  paymentMethod={cartMainData?.payment_method}
                  deliveryMethod={cartMainData?.delivery_method}
                />
              </div>
            </>
          )
        };
      default:
        return {
          currentStepIndex: 0,
          title: '',
          content: <div />
        };
    }
  };

  const commentError = useMemo(
    () => validationErrors?.find((item) => item.property_path === 'comment')?.message,
    [validationErrors]
  );

  const handleOrderButtonClick = async (data = {}) => {
    setIsLoading(true);

    await putCartMainDataAsync({
      comment,
      receiver
    });

    if (checkoutStep === 'BILLING') {
      await changeCartNewsletter({ cart_id: cartId });
      await validateCart({
        verification_scope: 'receiverAddress|clientAddress|paymentMethod|deliveryMethod'
      });

      setCheckoutStep('SUMMARY');
      return;
    }

    if (checkoutStep === 'SUMMARY') {
      const { status } = await validateCart({ verification_scope: 'all' });
      status === 1 && confirmCart(data);

      return;
    }

    preConfirmCart();
  };

  const { content } = getCurrentStepData();

  if (isConfirmation) {
    return <Confirmation cartId={cartId} />;
  }

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Checkout')}>
      <Container fullWidth>
        <Breadcrumbs fullWidth />
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{content}</div>
        </div>
      </Container>
    </div>
  );
};

export default Checkout;
