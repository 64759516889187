// Hook odpowiedzialny za aktualozację newslettera w koszyku

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  cart_id: number;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putCartNewsletter = (cartId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/carts/carts/${cartId}/newsletter`, data);

export const usePutCartNewsletter = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putCartNewsletter(cartId, data), options);
