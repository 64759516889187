// podsumowanie koszyka - box w sidebar

import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import { useGetCart, useGetCartValidatingMessage } from 'api';
import { useAppNavigate, useRWD } from 'hooks';
import { Button, Checkbox } from 'components/controls';
import { ICartData, ICartConfirmRequest, ICartMainData } from 'api/types';

import { CarbonDeliveryIcon, MoneyIcon } from 'assets/icons';

import styles from 'theme/components/containers/CartSummary/CartSummary.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  buttonOnClick: (data?: ICartConfirmRequest) => void;
  clearCart?: () => void;
  buttonLabel?: string;
  isLoading?: boolean;
  isSummary?: boolean;
  isBilling?: boolean;
  isMobileCheckoutBottom?: boolean;
  isCart?: boolean;
  paymentMethod?: ICartMainData['payment_method'];
  deliveryMethod?: ICartMainData['delivery_method'];
  isSubscribed?: ICartMainData['is_client_subscribed_to_newsletter'];
  isNewsletterSubscribed?: boolean;
  setIsNewsletterSubscribed?: Dispatch<SetStateAction<boolean>>;
}

const CartSummary: FC<IProps> = ({
  cartId,
  buttonOnClick,
  isLoading,
  isSummary,
  isBilling,
  isMobileCheckoutBottom,
  isCart,
  paymentMethod,
  deliveryMethod,
  isSubscribed,
  isNewsletterSubscribed,
  setIsNewsletterSubscribed
}) => {
  const navigate = useAppNavigate();
  const { isMobile } = useRWD();

  // pobranie szczegółów koszyka
  const { data: cartData } = useGetCart(cartId, {
    onSuccess: () => {
      refetchValidatingMessage();
    },
    onError: () => {
      navigate('/');
    }
  });

  const { data: validatingMessageData, refetch: refetchValidatingMessage } =
    useGetCartValidatingMessage(cartId);

  const renderMissingOrder = (cartData: ICartData) => {
    return (
      <span className={styles.missingOrder}>
        <Trans>Brakuje</Trans> {cartData.missing_to_order_net_formatted} {cartData.currency}{' '}
        <Trans>netto</Trans>, <Trans>aby złożyć zamówienie u producenta.</Trans>
      </span>
    );
  };

  const summaryWrapperHeight =
    document.getElementById('summary-wrapper')?.getBoundingClientRect().height || 0;

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        isCart && styles.isCart,
        isSummary && styles.isSummary,
        'StylePath-Components-Containers-CartSummary',
        { [styles.isMobileCheckoutBottom]: !!isMobileCheckoutBottom }
      )}>
      <div id="summary-wrapper" style={{ top: `calc(100vh - ${summaryWrapperHeight}px)` }}>
        {isSummary && (
          <div className={styles.heading}>
            <Trans>Podsumowanie</Trans>
          </div>
        )}
        {!isMobile &&
          cartData?.missing_to_order_net_formatted &&
          !isSummary &&
          renderMissingOrder(cartData)}
        {!isSummary &&
          validatingMessageData?.items.map((item, i) => (
            <p className={styles.validatingMessage} key={i}>
              {item.message}
            </p>
          ))}
        {isMobile &&
          cartData?.missing_to_order_net_formatted &&
          !isSummary &&
          renderMissingOrder(cartData)}
        <div className={styles.actionsWrapper}>
          <div className={styles.priceWrapper}>
            <div className={styles.valueTotalNet}>
              <div>
                {isCart ? (
                  <Trans>Łączna wartość produktów</Trans>
                ) : (
                  <Trans>Wartość produktów</Trans>
                )}
              </div>
              <div className={styles.value}>
                {cartData?.total_price_net_formatted && (
                  <>
                    {cartData.total_discount_net && (
                      <span className={styles.oldPrice}>
                        {cartData.value_base_net_with_services_formatted?.replace('.', ',')}{' '}
                        {cartData.currency} <Trans>netto</Trans>
                      </span>
                    )}{' '}
                    <span className={classnames(cartData.total_discount_net && styles.discount)}>
                      {cartData.positions_value_net_formatted?.replace('.', ',')}{' '}
                      {cartData.currency} <Trans>netto</Trans>
                    </span>{' '}
                  </>
                )}
              </div>
            </div>

            {!isCart && (
              <>
                <div className={styles.shippingCost}>
                  <div>
                    <Trans>Koszt wysyłki</Trans>
                  </div>
                  <div>
                    {deliveryMethod?.price_net_formatted?.replace('.', ',')} {cartData?.currency}{' '}
                    <Trans>netto</Trans>
                  </div>
                </div>
                {!!paymentMethod?.price_net && (
                  <div className={styles.shippingCost}>
                    <div>
                      <Trans>Koszt płatności</Trans>
                    </div>
                    <div>
                      {paymentMethod?.price_net_formatted?.replace('.', ',')} {cartData?.currency}{' '}
                      <Trans>netto</Trans>
                    </div>
                  </div>
                )}

                {map(cartData?.tax_details.tax_list, (tax) => (
                  <div className={styles.grossValue}>
                    <div>
                      <Trans>VAT</Trans> {tax.tax_rate}%
                    </div>
                    <div>
                      {tax.total_sum_tax_formatted?.replace('.', ',')} {cartData?.currency}
                    </div>
                  </div>
                ))}
                <div className={styles.valueTotalGross}>
                  <div>
                    <Trans>Razem do zapłaty</Trans>
                  </div>
                  <div className={styles.value}>
                    {cartData?.total_price_gross_formatted && (
                      <>
                        {cartData.total_discount_gross && (
                          <span className={styles.oldPrice}>
                            {cartData.total_old_price_gross_formatted?.replace('.', ',')}{' '}
                            {cartData.currency} <Trans>brutto</Trans>
                          </span>
                        )}{' '}
                        <span
                          className={classnames(
                            cartData.total_old_price_gross_formatted && styles.discount
                          )}>
                          {cartData.total_price_gross_formatted?.replace('.', ',')}{' '}
                          {cartData.currency} <Trans>brutto</Trans>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {isBilling && !isSubscribed && (
              <div className={styles.newsletterSubscription}>
                <Checkbox
                  size="small"
                  checked={!!isNewsletterSubscribed}
                  onClick={() => setIsNewsletterSubscribed?.(!isNewsletterSubscribed)}
                />{' '}
                <Trans>Zapisuję się do newslettera</Trans>
              </div>
            )}

            {cartData?.total_discount_gross_formatted && (
              <div className={styles.totalDiscount}>
                <Trans>Oszczędzasz</Trans>{' '}
                <b>
                  {cartData.total_discount_gross_formatted?.toString()?.replace('.', ',')}{' '}
                  {cartData.currency}
                </b>
              </div>
            )}
          </div>

          <div className={styles.buttonWrapper}>
            {isSummary ? (
              <>
                <Button onClick={() => buttonOnClick({ enabled: true })} loading={isLoading}>
                  <Trans>Potwierdzam i proszę o realizację</Trans>
                </Button>
                <Button onClick={() => buttonOnClick({ enabled: false })} loading={isLoading}>
                  <Trans>Potwierdzam i proszę o wstrzymanie</Trans>
                </Button>
                <Button onClick={() => navigate('/')} ghost>
                  <Trans>Wróć do zakupów</Trans>
                </Button>
              </>
            ) : (
              <>
                {isCart ? (
                  <>
                    <Button ghost onClick={() => navigate('/')}>
                      <Trans>Kontynuuj zakupy</Trans>
                    </Button>
                    <Button onClick={() => buttonOnClick()} loading={isLoading}>
                      <Trans>Zamawiam</Trans>
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => buttonOnClick()} loading={isLoading}>
                    <Trans>Zamawiam z obowiązkiem zapłaty</Trans>
                  </Button>
                )}
              </>
            )}
          </div>
          {isCart && (
            <div>
              <div className={styles.freeShipping}>
                <MoneyIcon /> <Trans>Darmowa wysyłka już od</Trans> 1200 PLN!{' '}
              </div>
              <div className={styles.shipping}>
                <CarbonDeliveryIcon /> <Trans>Wysyłka w 24h!</Trans>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
