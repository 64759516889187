// Hook odpowiedzialny za aktualozację main data koszyka

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

const requestKeys = [
  'customer.name',
  'customer.address.street',
  'customer.address.postal_code',
  'customer.address.city',
  'customer.nip',
  'customer.email',
  'customer.phone',
  'receiver_id',
  'name',
  'first_name',
  'last_name',
  'email',
  'phone',
  'address.street',
  'address.building',
  'address.apartment',
  'address.postal_code',
  'address.city',
  'address.state',
  'address.country',
  'address.country_code',
  'delivery_address_id',
  'delivery_method_id',
  'payment_method_id',
  'comment',
  'e_invoice',
  'e_invoice_email',
  'receiver_delivery_point.type',
  'receiver_delivery_point.symbol',
  'receiver_delivery_point.address.street',
  'receiver_delivery_point.address.building',
  'receiver_delivery_point.address.apartment',
  'receiver_delivery_point.address.postal_code',
  'receiver_delivery_point.address.city',
  'receiver_delivery_point.address.state',
  'receiver_delivery_point.address.country',
  'receiver_delivery_point.address.country_code',
  'cash_on_delivery_value'
];

interface IReceiver {
  name: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  address: {
    street: string;
    building?: string;
    apartment?: string;
    postal_code: string;
    city: string;
    state?: string;
    country?: string;
    country_code?: string;
  };
}

export interface IReceiverPoint {
  type: string;
  symbol: string;
  address: {
    street: string;
    building: string;
    apartment: string;
    postal_code: string;
    city: string;
    state: string;
    country?: string;
    country_code?: string;
  };
}

// parametry requestu do api
export interface IRequest {
  customer?: {
    name: string;
    address: {
      street: string;
      postal_code: string;
      city: string;
    };
    nip: string;
    email: string;
    phone?: string;
  };
  receiver_id?: number | null;
  receiver?: IReceiver;
  delivery_address_id?: number;
  delivery_method_id?: number;
  payment_method_id?: number;
  comment?: string;
  e_invoice?: boolean;
  e_invoice_email?: string;
  receiver_delivery_point?: IReceiverPoint;
  cash_on_delivery_value?: number;
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const putCartMainData = (cartId: number, data: IRequest): Promise<IResponse> =>
  axios.put(`/carts/${cartId}/maindata`, data, {
    env: { FormData: { requestKeys: requestKeys } as never }
  });

export const usePutCartMainData = (
  cartId: number,
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => putCartMainData(cartId, data), options);
