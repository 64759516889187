// szczegóły produktu

import React, { FC, useMemo, useState, Dispatch, SetStateAction, useCallback } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { useGetProductExportImages } from 'api';
import { IProduct, IProductLabel, IPosition } from 'api/types';
import { useSelector } from 'store';
import { useRWD } from 'hooks';
import { Label, Link, Modal, Collapse, Loader } from 'components/controls';
import { SizingTable } from 'pages/Product/components';
import { AddToShoppingListButton } from 'components/containers';

import styles from 'theme/pages/Product/components/Details/Details.module.scss';
import { Download, ContactIcon, TechnicalIcon } from 'assets/icons';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  labels: IProductLabel[];
  positions: IPosition[];
  setPositions: Dispatch<SetStateAction<IPosition[]>>;
}

const Details: FC<IProps> = ({ product, labels, positions, setPositions }) => {
  const { profile } = useSelector((state) => state.auth);
  const { isMobile } = useRWD();

  // loader
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: downloadImages } = useGetProductExportImages(product.id, {
    enabled: false,
    onSuccess: async (data) => {
      setIsLoading(false);

      const downloadLink = document.createElement('a');
      downloadLink.href = data.url;
      downloadLink.download = data.file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(data.url);
    },
    onError: () => {
      setIsLoading(false);
    }
  });

  const handleClick = () => {
    setIsLoading(true);
    downloadImages();
  };

  // pokazywanie popupa z tabelą rozmiarów
  const [isSizingTableVisible, setSizingTableVisible] = useState(false);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.unit_id === product.units[0]?.unit_id),
    [product]
  );

  const sizingTableContent = () => ({ __html: product.sizing_table });

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div className={classnames(styles.shoppingListButtonWrapper)}>
        {product && (
          <AddToShoppingListButton
            product={product}
            unit={unit}
            quantity={1}
            isShoppingList={false}
          />
        )}
      </div>
    ),
    [profile]
  );

  const renderDescription = () =>
    isMobile ? (
      <Collapse
        collapsed={false}
        title={
          <div className={styles.collapseTitle}>
            <Trans>Opis</Trans>
          </div>
        }>
        <div className={styles.description}>
          <div
            itemProp="description"
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: product.description_full }}
          />
        </div>
      </Collapse>
    ) : (
      <>
        <div className={styles.colapseWrapper}>
          <Collapse
            collapsed={false}
            title={
              <div className={styles.collapseTitle}>
                <Trans>Opis</Trans>
              </div>
            }>
            <div className={styles.description}>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: product.description_full }}
              />
            </div>
          </Collapse>
        </div>
      </>
    );

  const renderTechnicalInformation = () =>
    isMobile ? (
      <Collapse
        collapsed={false}
        customArrowDown={<TechnicalIcon />}
        customArrowUp={<TechnicalIcon />}
        title={
          <div className={styles.collapseTitle}>
            <Trans>Informacje techniczne</Trans>
          </div>
        }>
        <div className={styles.description}>
          <div
            itemProp="description"
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: product.gpsr_attributes_dto?.[0].description }}
          />
        </div>
      </Collapse>
    ) : (
      <>
        <div className={styles.colapseWrapper}>
          <Collapse
            collapsed={false}
            customArrowDown={<TechnicalIcon />}
            customArrowUp={<TechnicalIcon />}
            title={
              <div className={styles.collapseTitle}>
                <Trans>Informacje techniczne</Trans>
              </div>
            }>
            <div className={styles.description}>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: product.gpsr_attributes_dto?.[0].description }}
              />
            </div>
          </Collapse>
        </div>
      </>
    );

  return (
    <div
      className={classnames(styles.productDetails, 'StylePath-Pages-Product-components-Details')}>
      <div className={styles.mainContent}>
        <h1 itemProp="name" className={styles.title}>
          {product.title}
        </h1>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && unit && (
          <>
            <div className={styles.priceWrapper}>
              <span className={classnames(styles.nettoPrice, styles.oldPrice)}>
                {product.old_price_formatted_to_show && (
                  <>
                    {product.old_price_formatted_to_show?.replaceAll('.', ',')} {product.currency}{' '}
                    <Trans>netto</Trans>
                  </>
                )}
              </span>
              <span
                className={classnames(styles.nettoPrice, {
                  [styles.discountPrice]: !!product.old_price_formatted_to_show
                })}>
                {product.price_formatted_to_show?.replaceAll('.', ',')} {product.currency}{' '}
                <Trans>netto</Trans>
              </span>
              {!isMobile && (
                <>
                  <span className={styles.eurPrice}>
                    <Trans>ok.</Trans> {product.eur_price_formatted_to_show.replace('.', ',')} EUR
                  </span>
                  <span className={styles.retailPrice}>
                    <Trans>sugerowana cena detaliczna:</Trans>{' '}
                    {unit.retail_price_formatted.replace('.', ',')} {product.currency}{' '}
                    <Trans>brutto</Trans>
                  </span>
                </>
              )}
            </div>
            <div className={styles.deliveryInfo}>{isMobile && <span>Wysyłka w 24h!</span>}</div>
          </>
        )}

        {labels.length > 0 && !isMobile && (
          <div className={styles.labels}>
            {labels.map((label) => (
              <Label key={label.type} label={label} />
            ))}
          </div>
        )}

        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <SizingTable
            product={product}
            positions={positions}
            setPositions={setPositions}
            onClickSizingTable={() => setSizingTableVisible(true)}
          />
        )}

        {product.sizing_table && !isMobile && (
          <div className={styles.sizingTable} onClick={() => setSizingTableVisible(true)}>
            <span>
              <Trans>Tabela rozmiarów</Trans>
            </span>
          </div>
        )}

        {product.description_full && renderDescription()}

        {!!product?.gpsr_attributes_dto?.length && renderTechnicalInformation()}

        <a className={styles.detail} href={`mailto:${profile?.email_to_contact_owner_store}`}>
          <span>
            <Trans>Zapytaj o produkt</Trans>
          </span>
          <ContactIcon />
        </a>

        {profile?.role !== 'ROLE_OPEN_PROFILE' && !isMobile && (
          <button disabled={isLoading} className={styles.download} onClick={() => handleClick()}>
            <span>
              <Trans>Pobierz zdjęcia</Trans>
            </span>

            {isLoading ? <Loader /> : <Download />}
          </button>
        )}

        {/* ukrycie ulubionych */}
        {/* {profile?.role !== 'ROLE_OPEN_PROFILE' && isMobile && (
          <a className={styles.favourites}>
            <span>
              <Trans>Dodaj do ulubionych</Trans>
            </span>
            {renderAddToShoppingListButton()}
          </a>
        )} */}

        {profile?.role === 'ROLE_OPEN_PROFILE' && (
          <Link
            to={`/login?return_url=${encodeURIComponent(location.pathname + location.search)}`}
            className={styles.loginButton}>
            <Trans>Zaloguj się</Trans>{' '}
            <small>
              <Trans>aby sprawdzić cenę</Trans>
            </small>
          </Link>
        )}
      </div>

      {isSizingTableVisible && (
        <Modal
          onClose={() => {
            setSizingTableVisible(false);
          }}>
          <h3 className={styles.sizingTableTitle}>
            <Trans>Tabela rozmiarów</Trans>
          </h3>
          <div dangerouslySetInnerHTML={sizingTableContent()} />
        </Modal>
      )}
    </div>
  );
};

export default Details;
