// wrapper o szerokości wyświetlanej zawartości

import React, { ReactNode } from 'react';
import { Container as ContainerMui } from '@mui/material';

const Container = ({ children, fullWidth }: { children: ReactNode; fullWidth?: boolean }) => {
  return (
    <ContainerMui
      sx={!fullWidth ? { maxWidth: '1464px !important' } : {}}
      maxWidth={fullWidth ? 'xxl' : 'lg'}
      disableGutters>
      {children}
    </ContainerMui>
  );
};

export default Container;
